import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, DatePicker, Dialog, Input } from 'sw-ui';

import AjaxButton from '../../../../../components/ajaxButton';

import { getEndDayOfYear, isBeforeDateParameter } from '../../../../../bi/utils/formatDate';
import { getCurrentDate } from '../../../../../utils/date';
import { changeCommaToThePoint } from '../../../../../bi/utils/trip';

import styles from '../styles/dialog.module.css';

const headerOpts = {
  credit: 'Поступление',
  creditByCard: 'Оплата картой',
  debit: 'Списание',
};

const LABELS = {
  SUM: 'Сумма',
  DESCRIPTION: 'Описание',
  DATE: 'Дата',
  COMISSION: 'Комиссия за транзакцию',
  LAST_DATE: 'Списание комиссии прошедшей датой.',
  DOCUMENTS: 'Проверьте и скорректируйте пакет закрывающих документов при необходимости',
  ADD: 'Добавить',
  CANCEL: 'Отменить',
};

const COMPONENTNAME = 'debitOrCreditDialogState';
const COMPONENTVALIDATIONNAME = 'debitOrCreditDialog';
const DEBITDIALOG = 'debit';

class DebitOrCreditDialog extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    validation: PropTypes.object.isRequired,
    onAddDebitOrCredit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      waitingResponse: false,
      isCardFee: false,
      pastDate: false,
      currentDate: getCurrentDate(),
    };
  }

  componentWillUnmount() {
    this.props.companyService.finance.resetValidation();
    this.props.companyService.finance.resetDebitOrCreditDialogState();
  }

  handleTransaction = (value) => {
    this.setState({
      isCardFee: value,
    });

    const commission = value ? LABELS.COMISSION : '';
    this.handleChangeDescription(commission);
    this.validateDescription(commission);
  };

  changeTextError = (date, currentDate) => {
    const textErrorFlag = date && isBeforeDateParameter(date, currentDate);

    this.setState({
      pastDate: textErrorFlag,
    });
  };

  validateAmount = amount =>
    this.props.companyService.finance.validation(COMPONENTVALIDATIONNAME, 'amount', amount);

  validateDescription = description =>
     this.props.companyService.finance.validation(COMPONENTVALIDATIONNAME, 'description', description);

  validateDate = () =>
    this.props.companyService.finance.validation(COMPONENTVALIDATIONNAME, 'date', this.props.companyService.get().finance.debitOrCreditDialogState.date);

  handleChangeDate = ({ value }) => {
    const { state } = this.props;
    const { currentDate } = this.state;
    const date = state.date;

    if ((typeof date === 'string') || (date.isSame && !date.isSame(value))) {
      this.props.companyService.finance.setComponentState(COMPONENTNAME, 'date', value);
    }

    this.changeTextError(value, currentDate);
  };

  handleChangeAmount = (value) => {
    this.props.companyService.finance.setComponentState(COMPONENTNAME, 'amount', changeCommaToThePoint(value));
  };

  handleChangeDescription = value => this.props.companyService.finance.setComponentState(COMPONENTNAME, 'description', value);

  handleConfirm = () => {
    const { onAddDebitOrCredit, state: { date, amount, description } } = this.props;
    const { isCardFee } = this.state;

    const validAmt = this.validateAmount(amount);
    const validDescr = this.validateDescription(description);
    const validDate = this.validateDate(date);

    if (validAmt && validDescr && validDate) {
      const add = () => onAddDebitOrCredit(date, amount, description, isCardFee);

      this.setState({ waitingResponse: true }, add);
    }
  };

  render() {
    const {
      onClose,
      field,
      validation,
      state: {
        date,
        amount,
        description,
      },
      featureFlagsService: {
        getAddCardTransactionFee,
      },
    } = this.props;
    const { waitingResponse, isCardFee, pastDate } = this.state;

    const showComission = field === DEBITDIALOG;

    const errorLastDate = pastDate && isCardFee && (
      <div className={ styles.error }>
        <div className={ styles.text }>
          { LABELS.LAST_DATE }
        </div>
        { LABELS.DOCUMENTS }
      </div>
    );

    const checkComission = showComission && (
      <div className={ styles.wrapper }>
        <Checkbox
          onChange={ value => this.handleTransaction(value) }
          value={ isCardFee }
          disabled={ !getAddCardTransactionFee() }
        >
          { LABELS.COMISSION }
        </Checkbox>
        { errorLastDate }
      </div>
    );

    return (
      <Dialog onClick={ onClose } width='400px'>
        <div className={ styles.dialog }>
          <div className={ styles.header }>
            { headerOpts[field] }
          </div>
          <div className={ `${styles.row} ${validation.date ? styles['date-error'] : ''}` }>
            <div
              className={ styles.label }
            >
              { LABELS.DATE }
            </div>
            <DatePicker
              nullable
              type='date'
              value={ date }
              onChange={ this.handleChangeDate }
              onBlur={ this.validateDate }
              maxDate={ getEndDayOfYear() }
            />
            <div className={ styles['error-text'] }>{ validation.date }</div>
          </div>
          <div className={ styles.row }>
            <div
              className={ styles.label }
            >
              { LABELS.SUM }
            </div>
            <Input
              value={ amount }
              onChange={ this.handleChangeAmount }
              onBlur={ () => this.validateAmount(amount) }
              error={ validation.amount }
            />
          </div>
          <div className={ styles.row }>
            <div
              className={ styles.label }
            >
              { LABELS.DESCRIPTION }
            </div>
            <Input
              value={ description }
              onChange={ this.handleChangeDescription }
              onBlur={ () => this.validateDescription(description) }
              error={ validation.description }
              readOnly={ isCardFee }
            />
          </div>
          { checkComission }
          <div className={ styles.actions }>
            <div className={ styles.action }>
              <Button
                label={ LABELS.CANCEL }
                theme='flat'
                onClick={ onClose }
              />
            </div>
            <div className={ styles.action }>
              <AjaxButton
                label={ LABELS.ADD }
                loading={ waitingResponse }
                onClick={ this.handleConfirm }
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default DebitOrCreditDialog;
