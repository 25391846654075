import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Loading,
  RadioButton,
} from 'sw-ui';

import ResultSection from '../components/ResultSection';
import LoadFile from '../components/LoadFile';
import COMPONENTS from '../../../../../bi/constants/components';

import styles from '../styles/dialog.module.css';
import DecisionButtons from '../../../../../components/DecisionButtons';

const UPLOAD = {
  EMPLOYEE_DATA: '0',
  TRAVEL_POLICY: '1',
  RIGTHS_EMPLOYEE: '2',
};

const LABELS = {
  OK: 'Готово',
  CANCEL: 'Отменить',
  APROVE: 'Загрузить',
  CONFIRM: 'Подтвердить',
  DOWNLOAD: 'Скачать',
  UPLOAD: 'Обработать',
  DOWNLOAD_PATTERN: 'Скачать шаблон',
  UPLOAD_TYPE: 'Выберите тип загрузки',
  LOADER_TEXT: 'Происходит обработка файла',
  EMPLOYEES_TYPE: 'Загрузка данных по сотрудникам',
  RESULT_FILE: 'Результат загрузки доступен по кнопке',
  INFORMATION_TITLE: 'Предварительная обработка файла',
  RESULT_INFORMATION_TITLE: 'Результат загрузки файла',
  PROCESSED_FILE: 'Результат обработки доступен по кнопке',
  SUCCESSFUL_RESULT_TEXT: 'Загрузка сотрудников завершена',
  BULK_UPLOAD_TYPE: 'Загрузка ТП, ЦЗ и меток по сотрудникам',
  BULK_UPLOAD_RESULT: 'Результат загрузки настроек сотрудников',
  RIGHTS_UPLOAD_RESULT: 'Результат загрузки прав сотрудников',
  UPLOAD_RIGHTS_TYPE: 'Загрузка прав доступа сотрудников',
  LOAD_FILE_TITLE_EMPLOYEES: 'Выберите файл для загрузки сотрудников в компанию',
  LOAD_FILE_TITLE_BULK_UPLOAD: 'Выберите файл для загрузки настроек сотрудников',
  LOAD_FILE_TITTLE_RIGHTS: 'Выберите файл для загрузки прав сотрудников',
  PATTERN_INFO: 'Для загрузки необходимо использовать шаблон установленного образца',
  PROCESSED_FILE_BULK_UPLOAD: 'Результат загрузки настроек доступен по кнопке',
  NEW_EMPLOYEES_COUNT: count => `Новых сотрудников: ${count}`,
  UPDATE_EMPLOYEES_COUNT: count => `Обновляемых сотрудников: ${count}`,
  ALL_EMPLOYEES_COUNT: count => `Всего сотрудников к загрузке: ${count}`,
  UPDATE_EMPLOYEES_COUNT_UPLOAD: count => `Обновлено сотрудников: ${count}`,
  ERROR_BY_RECORD_EMPLOYEES: count => `Ошибка записи по ${count} сотрудникам`,
  NEW_EMPLOYEES_COUNT_UPLOAD: count => `Загружено новых сотрудников: ${count}`,
  UNCHANGEBLE_EMPLOYEES_COUNT_UPLOAD: count => `Сотрудников без изменений: ${count}`,
  SUCCESSFUL_LOADING_BY_EMPLOYEES: count => `Успешная загрузка по ${count} сотрудникам`,
  ERROR_EMPLOYEES_COUNT_UPLOAD: count => `Сотрудников с некорректными данными: ${count}`,
  SUCCESSFUL_LOADING_RIGHTS_EMPLOYEES: count => `Успешно загружены: ${count}`,
  ERROR_RIHTS_EMPLOYEES_COUNT: count => `Ошибка при загрузке: ${count}`,
  LOAD_TIMES: 'Загрузка может занять несколько минут, пожалуйста, ожидайте',
};

const DIALOG_WIDTH = '500px';

const patternType = {
  employeesUpload: 'ImportEmployees',
  bulkUpload: 'ImportEmployeeSettings',
  rightsEmployees: 'ImportEmployeeRights',
};

class DispatchOfEmployeeDialog extends Component {
  static propTypes = {
    companyId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    companyService: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const { file, processedFile } = props.companyService.get();

    this.state = {
      showContent: false,
      bulkUpload: null,
      file,
      processedFile,
    };
  }

  componentDidMount() {
    const { companyService } = this.props;

    this.unsubscribe = companyService.subscribe(this.update);
  }

  componentWillUnmount() {
    this.props.companyService.clearUploadedFile();

    this.unsubscribe();
  }

  update = ({ processedFile, file }) => {
    this.setState({
      processedFile,
      file,
    });
  };

  handleSwitchRadioType = value => this.setState({
    ...this.state,
    bulkUpload: value,
  })

  handleAproveOpenDialog = value => this.setState({
    ...this.state,
    showContent: value,
  })

  handleAproveDownloadEmployees = (companyId, updateGuid) => this.props.companyService.aproveDownloadEmployees(companyId, updateGuid);

  handleUploadEmployeeFile = () => {
    const { companyService: { sendUploadFile }, companyId } = this.props;
    const { file: { File } } = this.state;

    return sendUploadFile(File, companyId);
  };

  handleBulkUploadFile = async () => {
    const { companyService: { sendBulkUploadFile }, accountId } = this.props;
    const { file: { File } } = this.state;

    return sendBulkUploadFile(File, accountId);
  };

  handleDownloadRightsEmployees = async () => {
    const { companyService: { downloadRightsEmployees }, accountId, companyId } = this.props;
    const { file: { File } } = this.state;

    return downloadRightsEmployees(File, accountId, companyId);
  };

  handleDownloadEmployeesFile = fileLink => this.props.companyService.downloadEmployeesFile(fileLink);

  handleFileRemove = () => this.props.companyService.clearUploadedFile();

  handleFileAdded = file => this.props.companyService.uploadFile(file);

  downloadPattern = () => {
    const { bulkUpload } = this.state;
    const { companyId, companyService: { downloadPattern, getPatternRights } } = this.props;

    if (bulkUpload === UPLOAD.EMPLOYEE_DATA) {
      return downloadPattern(patternType.employeesUpload);
    }

    if (bulkUpload === UPLOAD.TRAVEL_POLICY) {
      return downloadPattern(patternType.bulkUpload);
    }

    return getPatternRights(patternType.rightsEmployees, companyId);
  };

  handleCloseFile = () => {
    const { onClose } = this.props;
    const {
      bulkUpload,
      processedFile: {
        loadDocument,
        secondPhase,
        information: {
          updateGuid,
        },
      },
    } = this.state;

    return onClose(false, loadDocument, secondPhase, updateGuid, bulkUpload);
  }

  renderMessage = errorMessage =>
    <span className={ `${styles['error-msg']} ${styles.error}` }>{ errorMessage }</span>;

  renderLoader = () => {
    const loaderText = this.state.uploaded && LABELS.LOADER_TEXT;

    return (
      <div className={ styles.loader }>
        {loaderText}
        <Loading />
      </div>
    );
  }

  formationResultProps = () => {
    const { companyId } = this.props;
    const {
      bulkUpload,
      processedFile: {
        secondPhase,
        information: {
          allEmployeesCount,
          successUploadCount,
          errorMessage,
          errorUploadCount,
          errorEmployeesCount,
          unchangeableEmployeesCount,
          newEmployeesCount,
          updateEmployeesCount,
          updateGuid,
        },
      },
    } = this.state;

    const textFromPhase = bulkUpload === UPLOAD.RIGTHS_EMPLOYEE ? LABELS.RIGHTS_UPLOAD_RESULT : LABELS.BULK_UPLOAD_RESULT;
    const textSuccessLoading = bulkUpload === UPLOAD.RIGTHS_EMPLOYEE ? LABELS.SUCCESSFUL_LOADING_RIGHTS_EMPLOYEES : LABELS.SUCCESSFUL_LOADING_BY_EMPLOYEES;
    const textErrorLoading = bulkUpload === UPLOAD.RIGTHS_EMPLOYEE ? LABELS.ERROR_RIHTS_EMPLOYEES_COUNT : LABELS.ERROR_BY_RECORD_EMPLOYEES;

    if (bulkUpload === UPLOAD.TRAVEL_POLICY || bulkUpload === UPLOAD.RIGTHS_EMPLOYEE) {
      const messageOfNewEployees = (
        <div className={ styles.employee_message }>
          <span className={ styles.main_row }>{LABELS.ALL_EMPLOYEES_COUNT(allEmployeesCount)}</span>
          <span>{ textSuccessLoading(successUploadCount) }</span>
          <span>{ textErrorLoading(errorUploadCount) }</span>
        </div>
      );

      return {
        messageOfNewEployees,
        messageFromPhase: LABELS.PROCESSED_FILE_BULK_UPLOAD,
        titleFromPhase: textFromPhase,
        aproveFuncFromPhase: this.handleCloseFile,
        aproveButtonFromPhase: LABELS.OK,
        errorMessage,
      };
    }

    const messageAboutAllEmployees = LABELS.ALL_EMPLOYEES_COUNT(allEmployeesCount);
    const messageAboutErrorEmployees = LABELS.ERROR_EMPLOYEES_COUNT_UPLOAD(errorEmployeesCount);
    const messageAboutUnchangebleEmployees = LABELS.UNCHANGEBLE_EMPLOYEES_COUNT_UPLOAD(unchangeableEmployeesCount);
    const messageAboutNewEmployees = secondPhase
        ? LABELS.NEW_EMPLOYEES_COUNT_UPLOAD(newEmployeesCount)
        : LABELS.NEW_EMPLOYEES_COUNT(newEmployeesCount);
    const messageAboutUpdateEmployees = secondPhase
        ? LABELS.UPDATE_EMPLOYEES_COUNT_UPLOAD(updateEmployeesCount)
        : LABELS.UPDATE_EMPLOYEES_COUNT(updateEmployeesCount);

    const messageOfNewEployees = (
      <div className={ styles.employee_message }>
        <span className={ styles.main_row }>{messageAboutAllEmployees}</span>
        <span>{messageAboutNewEmployees}</span>
        <span>{messageAboutUpdateEmployees}</span>
        <span>{messageAboutUnchangebleEmployees}</span>
        <span>{messageAboutErrorEmployees}</span>
      </div>
      );

    const messageFromPhase = secondPhase ? LABELS.RESULT_FILE : LABELS.PROCESSED_FILE;
    const titleFromPhase = secondPhase ? LABELS.SUCCESSFUL_RESULT_TEXT : LABELS.INFORMATION_TITLE;
    const aproveFuncFromPhase = secondPhase ? this.handleCloseFile : () => this.handleAproveDownloadEmployees(companyId, updateGuid);
    const aproveButtonFromPhase = secondPhase ? LABELS.OK : LABELS.APROVE;

    return {
      messageOfNewEployees,
      messageFromPhase,
      titleFromPhase,
      aproveFuncFromPhase,
      aproveButtonFromPhase,
      errorMessage,
    };
  };

  renderResultSection = () => {
    const {
      processedFile: {
        loadDocument,
        secondPhase,
        information,
        information: {
          errorMessage,
        },
      },
      file: {
        uploaded,
        failed,
      },
    } = this.state;

    const disableButtons = failed || !uploaded || !!errorMessage;

    return (
      <ResultSection
        loadDocument={ loadDocument }
        secondPhase={ secondPhase }
        information={ information }
        formationResultProps={ this.formationResultProps }
        disableButtons={ disableButtons }
        messageHtml={ this.renderMessage }
        onDownloadEmployeesFile={ this.handleDownloadEmployeesFile }
        onAproveDownloadEmployees={ this.handleAproveDownloadEmployees }
        onCloseFile={ this.handleCloseFile }
      />
    );
  }

  renderLoadFile = (title, saveFunc, saveLabel) => {
    const {
      file: {
        File,
        uploaded,
        failed,
        error,
      },
    } = this.state;

    const disableButtons = failed || !uploaded || !!error;

    return (
      <LoadFile
        title={ title }
        saveLabel={ saveLabel }
        file={ File }
        uploaded={ uploaded }
        failed={ failed }
        error={ error }
        disableButtons={ disableButtons }
        messageHtml={ this.renderMessage }
        downloadPattern={ this.downloadPattern }
        onFileAdded={ this.handleFileAdded }
        onFileRemove={ this.handleFileRemove }
        onCloseFile={ this.handleCloseFile }
        onSaveFunc={ saveFunc }
      />
    );
  }

  renderSwitchType = () => {
    const checkedBulkUpload = data => this.state.bulkUpload === data;

    return (
      <div className={ styles.switch }>
        <div className={ styles.title }>
          <h3>{ LABELS.UPLOAD_TYPE }</h3>
        </div>
        <div className={ styles.radios }>
          <div className={ styles.radio }>
            <RadioButton
              value
              checked={ checkedBulkUpload(UPLOAD.EMPLOYEE_DATA) }
              onChange={ () => this.handleSwitchRadioType(UPLOAD.EMPLOYEE_DATA) }
            >
              <div>{ LABELS.EMPLOYEES_TYPE }</div>
            </RadioButton>
          </div>
          <div className={ styles.radio }>
            <RadioButton
              value={ false }
              checked={ checkedBulkUpload(UPLOAD.TRAVEL_POLICY) }
              onChange={ () => this.handleSwitchRadioType(UPLOAD.TRAVEL_POLICY) }
            >
              <div>{ LABELS.BULK_UPLOAD_TYPE}</div>
            </RadioButton>
          </div>
          <div className={ styles.radio }>
            <RadioButton
              value={ false }
              checked={ checkedBulkUpload(UPLOAD.RIGTHS_EMPLOYEE) }
              onChange={ () => this.handleSwitchRadioType(UPLOAD.RIGTHS_EMPLOYEE) }
            >
              <div>{ LABELS.UPLOAD_RIGHTS_TYPE }</div>
            </RadioButton>
          </div>
          <DecisionButtons
            labelSave={ LABELS.CONFIRM }
            showCancel={ false }
            onSave={ () => this.handleAproveOpenDialog(true) }
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      bulkUpload,
      showContent,
      processedFile: {
        loading,
        loadDocument,
      },
      file: {
        uploaded,
      },
    } = this.state;

    const html = () => {
      if (loading) {
        const loaderText = uploaded && LABELS.LOADER_TEXT;

        return (
          <div className={ styles.loader }>
            { loaderText }
            <Loading size={ COMPONENTS.LOADING.SIZE.SMALL } />
            { LABELS.LOAD_TIMES }
          </div>
        );
      }

      if (!showContent) return this.renderSwitchType();

      if (loadDocument) return this.renderResultSection();

      if (bulkUpload === UPLOAD.EMPLOYEE_DATA) {
        return this.renderLoadFile(LABELS.LOAD_FILE_TITLE_EMPLOYEES, this.handleUploadEmployeeFile, LABELS.UPLOAD);
      }

      if (bulkUpload === UPLOAD.TRAVEL_POLICY) {
        return this.renderLoadFile(LABELS.LOAD_FILE_TITLE_BULK_UPLOAD, this.handleBulkUploadFile, LABELS.APROVE);
      }

      return this.renderLoadFile(LABELS.LOAD_FILE_TITTLE_RIGHTS, this.handleDownloadRightsEmployees, LABELS.APROVE);
    };

    return (
      <Dialog
        onClick={ this.handleCloseFile }
        showCloseButton
        width={ DIALOG_WIDTH }
      >
        <div className={ styles.wrap } >
          {html()}
        </div>
      </Dialog>
    );
  }
}

export default DispatchOfEmployeeDialog;
