import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../../../../bi/utils/formatDate';
import {
  STATUS_EXPENSE_REPORTS,
  STATUS_EXPENSE_REPORTS_RU,
} from '../../../../../bi/constants/company';

import { FlatButton } from '../../../../../components/button';

import styles from '../../../styles/trip.module.scss';

const LABELS = {
  DELETE_REPORT: 'Удалить',
};

const Report = ({ item, onOpenRemoveDialog, companyId }) => {
  const { CheckinDate, CheckoutDate, Name, Employee, Status, Stamp, Id } = item;

  const stylesRow = `${styles['col-1-7']} ${styles['expense-report']}`;
  const deletedStatus = Status === STATUS_EXPENSE_REPORTS.Deleted;
  const deleteStyle = deletedStatus ? styles['expense-report-delete'] : '';

  const deleteButton = !deletedStatus ? (
    <div className={ styles['col-1-7'] }>
      <FlatButton onClick={ () => onOpenRemoveDialog(Id, companyId, Employee, Name) }>{ LABELS.DELETE_REPORT }</FlatButton>
    </div>
  ) : null;

  return (
    <div className={ styles.row }>
      <div className={ stylesRow }>
        { formatDate(CheckinDate) }
      </div>
      <div className={ stylesRow }>
        { formatDate(CheckoutDate) }
      </div>
      <div className={ stylesRow }>
        { Name }
      </div>
      <div className={ stylesRow }>
        { Employee }
      </div>
      <div className={ `${stylesRow} ${deleteStyle}` }>
        { STATUS_EXPENSE_REPORTS_RU[Status] }
      </div>
      <div className={ stylesRow }>
        { formatDate(Stamp) }
      </div>
      { deleteButton }
    </div>
  );
};

Report.propTypes = {
  item: PropTypes.object,
  companyId: PropTypes.number.isRequired,
  onOpenRemoveDialog: PropTypes.func.isRequired,
};

Report.defaultProps = {
  item: null,
};

export default Report;
