import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'sw-ui';

import Report from './components/report';
import RemoveDialog from './components/removeDialog';

import styles from '../../styles/company.module.scss';

const LABELS = {
  NO_RESULT: 'Нет авансовых отчетов',
  EXPENSE_REPORTS: 'Авансовые отчеты',
  COLUMNS: {
    START_DATE: 'Дата начала',
    END_DATE: 'Дата окончания',
    CHANGE_DATE: 'Дата изменений',
    NAME: 'Название',
    EMPLOYEE: 'Сотрудник',
    STATUS: 'Статус',
  },
};

class ExpenseReportsPage extends Component {
  static propTypes = {
    companyId: PropTypes.number.isRequired,
    companyService: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    const {
      expenseReports: {
        data,
        isLoading,
        deleteItemLoading,
      },
    } = props.companyService.get();

    this.state = {
      data,
      isLoading,
      deleteItemLoading,
      removeDialog: {
        show: false,
        item: null,
      },
    };
  }

  componentDidMount() {
    const { companyService, companyId } = this.props;

    companyService.loadExpenseReports(companyId);

    this.unsubscribeFn = companyService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();

    this.props.companyService.resetExpenseReports();
  }

  updateState = ({ expenseReports: { isLoading, data } }) => this.setState({
    isLoading,
    data,
  });

  handleRemoveExpenseReportsItem = () => {
    const { companyService } = this.props;
    const { removeDialog: { item } } = this.state;

    companyService.deleteExpenseReportItem(item)
      .then(() => this.handleCloseRemoveDialog());
  };

  handleOpenRemoveDialog = (id, companyId, employee, name) => this.setState({
    removeDialog: {
      ...this.state.removeDialog,
      show: true,
      item: { id, companyId, employee, name },
    },
  });

  handleCloseRemoveDialog = () => this.setState({
    removeDialog: {
      ...this.state.removeDialog,
      show: false,
      item: null,
    },
  });

  renderEmptyList = () => <div className={ `empty-list ${styles['empty-list']}` }>{ LABELS.NO_RESULT }</div>;

  renderList = (list) => {
    if (list.length) {
      const {
        companyId,
      } = this.props;

      const reportList = list.map(item => (
        <Report
          key={ item.Id }
          item={ item }
          companyId={ companyId }
          onOpenRemoveDialog={ this.handleOpenRemoveDialog }
        />
      ));

      return (
        <div className={ styles.form }>
          <div className={ styles.row }>
            <div className={ styles['col-1-7'] }>
              <label>{ LABELS.COLUMNS.START_DATE }</label>
            </div>
            <div className={ styles['col-1-7'] }>
              <label>{ LABELS.COLUMNS.END_DATE }</label>
            </div>
            <div className={ styles['col-1-7'] }>
              <label>{ LABELS.COLUMNS.NAME }</label>
            </div>
            <div className={ styles['col-1-7'] }>
              <label>{ LABELS.COLUMNS.EMPLOYEE }</label>
            </div>
            <div className={ styles['col-1-7'] }>
              <label>{ LABELS.COLUMNS.STATUS }</label>
            </div>
            <div className={ styles['col-1-7'] }>
              <label>{ LABELS.COLUMNS.CHANGE_DATE }</label>
            </div>
          </div>
          { reportList }
        </div>
      );
    }

    return null;
  };

  renderRemoveDialog = () => {
    const { removeDialog: { item, show }, deleteItemLoading } = this.state;
    const preparedItem = {
      ...item,
      deleteItemLoading,
    };

    return show ? (
      <RemoveDialog
        item={ preparedItem }
        onDelete={ this.handleRemoveExpenseReportsItem }
        onClose={ this.handleCloseRemoveDialog }
      />
    ) : null;
  };

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading
        size='large'
      />
    </div>
  );

  render() {
    const {
      isLoading,
      data,
    } = this.state;

    const html = data && data.length > 0 ? this.renderList(data) : this.renderEmptyList();

    return isLoading ? this.renderLoading() : (
      <div className={ styles['trip-company'] }>
        <div className={ styles.panel }>
          <div className={ styles.title }>
            <div className={ styles['panel-name'] }>{ LABELS.EXPENSE_REPORTS }</div>
          </div>
          { html }
        </div>
        { this.renderRemoveDialog() }
      </div>
    );
  }

}
export default ExpenseReportsPage;
