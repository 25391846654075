import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Loading,
  COMPONENTS,
} from 'sw-ui';

import DispatchOfEmployeeDialog from './dialogs/DispatchOfEmployeeDialog';
import LinkToNewTab from '../../../../components/LinkToNewTab';

import styles from './styles/index.module.css';

// допустимые значения: 4, 6, 8
const COLSNUMBER = 6;

const LABELS = {
  DOWNLOAD_EMPLOYEES: 'Массовая загрузка',
  UNLOADING_EMPLOYEES: 'Выгрузка сотрудников',
  ACTIVE_EMPLOYEES: amount => `Активные сотрудники: ${amount}`,
  ARCHIVE_EMPLOYEES: amount => `Архивные сотрудники: ${amount}`,
};

class Employees extends Component {
  static propTypes = {
    companyId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    companyService: PropTypes.object.isRequired,
    accessToUploadEmployees: PropTypes.func.isRequired,
    accessToExportEmployees: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { employees, archivedEmployees } = props.companyService.get();

    this.state = {
      employees,
      archivedEmployees,
      loading: true,
      dispatchEmployeesDialog: false,
    };
  }

  componentDidMount() {
    const { companyService } = this.props;

    this.unsubscribeCompany = companyService.subscribe(this.updateCompany);

    companyService.employees.loadEmployees();
  }

  componentWillUnmount() {
    this.unsubscribeCompany();
  }

  updateCompany = ({ employees, archivedEmployees }) =>
    this.setState({ loading: false, employees, archivedEmployees });

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading size='large' />
    </div>
  );

  renderEmptyList = () => (<div className={ `empty-list ${styles.empty}` }>Нет сотрудников</div>);

  handleUnloadEmployees = () => this.props.companyService.unloadEmployees(this.props.companyId);

  renderRow = (employees, cols, rows, rowIdx) => {
    const row = [];

    for (let i = 0; i < cols; i++) {
      const item = employees[(i * rows) + (rowIdx - 1)];

      if (item) {
        row.push(
          <div className={ styles[`col-1-${cols}`] } key={ i }>
            <LinkToNewTab to={ `/employeePage/${item.Id}` }>
              <span className={ styles.employee }>
                { item.Surname } { item.Name.charAt(0) }. { item.Patronymic ? `${item.Patronymic.charAt(0)}.` : '' }
              </span>
            </LinkToNewTab>
          </div>
        );
      }
    }

    return row;
  };

  openDispatchEmployeeModal = async (value, loadDocument, secondPhase, updateGuid, bulkUpload) => {
    const {
      companyId,
      companyService: {
        cancellationOfProcessingDownloadEmployees,
      },
    } = this.props;

    if (loadDocument && !secondPhase && !bulkUpload) {
      await cancellationOfProcessingDownloadEmployees(companyId, updateGuid);
    }

    this.setState({
      dispatchEmployeesDialog: value,
    });
  }

  renderHeaderTable = (isArchived, employees) => {
    const header = isArchived
      ? LABELS.ARCHIVE_EMPLOYEES(employees.length)
      : LABELS.ACTIVE_EMPLOYEES(employees.length);

    return (
      <div className={ styles.header }>
        <div>
          {header}
        </div>
      </div>
    );
  };

  renderTableContent = (employees, cols, rows) => {
    const html = [];

    for (let rowIdx = 1; rowIdx <= rows; rowIdx++) {
      html.push(
        <div className={ styles['table-row'] } key={ rowIdx }>
          { this.renderRow(employees, cols, rows, rowIdx) }
        </div>
      );
    }

    return html;
  };

  renderTable = (employees, isArchived = false) => {
    if (!employees.length) {
      return null;
    }

    const cols = COLSNUMBER;
    const rows = Math.ceil(employees.length / cols);

    return (
      <div className={ styles.table }>
        { this.renderHeaderTable(isArchived, employees) }
        { this.renderTableContent(employees, cols, rows) }
      </div>
    );
  };

  renderDispatchEmployeesDialog = () => this.state.dispatchEmployeesDialog && (
    <DispatchOfEmployeeDialog
      companyId={ this.props.companyId }
      accountId={ this.props.accountId }
      companyService={ this.props.companyService }
      onClose={ this.openDispatchEmployeeModal }
    />
  );

  render() {
    const { loading,
      employees,
      archivedEmployees,
    } = this.state;
    const { accessToUploadEmployees,
      accessToExportEmployees,
    } = this.props;

    if (loading) {
      return (
        <div className={ styles.wrap }>
          { this.renderLoading() }
        </div>
      );
    }

    const emptyListHtml = !employees.length && !archivedEmployees.length && this.renderEmptyList();

    const unloadEmployees = accessToExportEmployees() && (
      <Button
        label={ LABELS.UNLOADING_EMPLOYEES }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ () => this.handleUnloadEmployees() }
      />
    );

    return (
      <div className={ styles.wrap }>
        {emptyListHtml}
        <div className={ styles.modal_button }>
          { unloadEmployees }
          <Button
            disabled={ !accessToUploadEmployees() }
            label={ LABELS.DOWNLOAD_EMPLOYEES }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ () => this.openDispatchEmployeeModal(true) }
          />
        </div>
        { this.renderTable(employees) }
        { this.renderTable(archivedEmployees, true) }
        { this.renderDispatchEmployeesDialog() }
      </div>
    );
  }
}

export default Employees;
